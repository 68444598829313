
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import log from 'loglevel';

import { useAuth0 } from "@auth0/auth0-react";

import 'bootstrap/dist/css/bootstrap.min.css';

import {
	Container,
	Row,
	Col
} from 'reactstrap';
  
import { TopNav } from './components/TopNav';
import { SideBar } from './components/SideBar';
import { PrivateRoute } from './components/PrivateRoute';
import { Status } from './components/Status';

// scenes
import { Home } from './scenes/Home';

log.setLevel ( "info" );

export function App ( props )
{
    const { isLoading, error } = useAuth0();

    if (error)
    {
        return <div>Oops... {error.message}</div>;
    }

    if (isLoading)
    {
        return <div>...</div>;
    }
  
    return (
        <div className="min-vh-100 d-flex flex-column justify-content-between">
            <div className="contentWrapper">
                <BrowserRouter>

                    {/* top menu */}
                    <TopNav />

                    {/* left side menu and content */}
                    <Container fluid="true">
                        <Row>
                            <Col xs="4" sm="4" md="2" lg="1" xl="1">
                                <SideBar />
                            </Col>
                            <Col xs="8" sm="8" md="10" lg="11" xl="11">
                                <Switch>
                                    <Route exact path='/' component={Home} />

                                </Switch>
                            </Col>
                        </Row>
                    </Container>
                </BrowserRouter>
            </div>
            <footer>
                <Status />
            </footer>
        </div>
	);
}
