import React /*, { useState }*/ from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import {
  Navbar,
  NavbarBrand,
} from 'reactstrap';


function renderUserInfo ( user, isAuthenticated, isLoading )
{
  if ( !isLoading && isAuthenticated )
  {
    return <span>{user.name}</span>
  }
  return <span />;
}

export function TopNav(props)
{
  const { user, isAuthenticated, isLoading } = useAuth0();

  const userInfo = renderUserInfo ( user, isAuthenticated, isLoading );

  return (
    <div>
      <Navbar color="faded" light>
        <NavbarBrand href="/" className="me-auto">
            nebby
        </NavbarBrand>

        {userInfo}

      </Navbar>
    </div>
  );
}
